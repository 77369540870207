import React, { useContext } from "react"
import {
    AppBar,
    Box,
    Button,
    Grid,
    Toolbar,
    Typography
} from '@mui/material';
import hseoLogo from './assets/images/HSEO_logo.svg';
import { useNavigate } from "react-router-dom";
import { AccountContext } from "./auth/Account";

export default function MainAppBar() {
    const navigate = useNavigate();
    const { logout } = useContext(AccountContext);

    const handleLogout = () => {
        logout();
        navigate('/')
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar className="appbar" position="static">
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container item alignItems="center">
                            <Grid item xs={1.5}>
                                <img src={hseoLogo} alt="Logo" className="logo" />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Button onClick={handleLogout} key="signOut" sx={{ color: '#fff' }}>
                            Log Out
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}