import { CognitoUserPool } from "amazon-cognito-identity-js"

const REACT_APP_USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID
const REACT_APP_CLIENT_ID = process.env.REACT_APP_CLIENT_ID

// Define the user pool data
const poolData = {
    UserPoolId: REACT_APP_USER_POOL_ID,
    ClientId: REACT_APP_CLIENT_ID
};

export default new CognitoUserPool(poolData);