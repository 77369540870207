import './styles/App.css';
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { AccountContext } from "./auth/Account";
import FileUploader from './FileUploader'
import Submissions from './Submissions'
import MainAppBar from './MainAppBar';
import AppFooter from './AppFooter';
import Progress from './Progress';

import {
    Box,
    Typography,
} from '@mui/material'

/**
 * Represents the Dashboard component.
 * @returns {JSX.Element} The rendered Dashboard component.
 */
function Dashboard() {
    const navigate = useNavigate();
    const [userCompanyDomain, setUserCompanyDomain] = useState(null);
    const [pageLoaded, setPageLoaded] = useState(false);
    const { getSession } = useContext(AccountContext);

    const ENV = process.env?.REACT_APP_ENV === "dev" ? process.env?.REACT_APP_ENV : "";

    useEffect(() => {
        getSession().then(async (session) => {
            if (!session) {
                navigate('/');
            }

            setUserCompanyDomain(session.attributes['custom:company_domain']);
            setPageLoaded(true);
        }).catch(error => {
            console.error('Error fetching session:', error);
            navigate('/');
        });
    }, []);

    return pageLoaded ? (
        <>
            <MainAppBar />
            <Box className="main-container">
                <Typography
                    variant="h3"
                    sx={{ display: { xs: 'none', sm: 'block' }, fontWeight: 700, fontSize: '35px', textShadow: '2px 2px 8px rgba(0,0,0,0.1)' }}
                >
                    EIIRP Program Dashboard {ENV !== "" && <span style={{ "color": "red" }}>(env: {ENV})</span>}
                </Typography>

                <Box sx={{ mt: 2 }} >
                    <FileUploader />
                </Box>

                <Box sx={{ mt: 2 }} >
                    <Submissions userCompanyDomain={userCompanyDomain} />
                </Box>
            </Box>
            <AppFooter />
        </>
    ) : <Progress text="Preparing your dashboard..." />;
}

export default Dashboard;