import React, { useState } from 'react';
import Dashboard from './Dashboard'
import { HashRouter, Routes, Route } from 'react-router-dom';
import AuthWrapper from './auth/AuthWrapper';
import ChangePassword from './auth/ChangePassword';
import { Account } from './auth/Account';

function App() {
  return (
    <Account>
      <HashRouter>
        <Routes>
          <Route path="/" element={<AuthWrapper />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </HashRouter>
    </Account>
  )
}

export default App;