import React, { useContext } from "react"
import {
    Box,
    Typography,
} from '@mui/material';

export default function AppFooter() {
    const CONTACT_US_EMAIL = process.env.REACT_APP_CONTACT_US_EMAIL || "";

    return (
        <Box className="app-footer">
            <Typography variant="h6">
                Please contact <b>{CONTACT_US_EMAIL}</b> if you need assistance.
            </Typography>
        </Box>
    );
}