import React, { useEffect, useState, useContext } from "react"
import { useNavigate } from "react-router-dom";
import { AccountContext } from "./Account";
import {
    Box,
    Tab,
    Typography,
    Grid
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Login from './Login';
import Signup from './Signup';
import ForgotPassword from './ForgotPassword';
import MFA from './MFA';
import hseoLogo from '../assets/images/HSEO_logo.svg';

export default function AuthWrapper() {
    const navigate = useNavigate();

    const APP_NAME = process.env.REACT_APP_NAME || "";

    const [currentTab, setCurrentTab] = useState("1");
    const [secretCode, setSecretCode] = useState(null);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [showMFAScreen, setShowMFAScreen] = useState(false);
    const [mfaEnabled, setMFAEnabled] = useState(false);

    const { getSession } = useContext(AccountContext);

    useEffect(() => {
        getSession().then(async (session) => {
            if (session) navigate('/dashboard');
        }).catch(error => {
            console.error('Error fetching session:', error);
            navigate('/');
        });
    }, []);


    const handleTabChange = (event, value) => {
        setCurrentTab(value);
    };

    return (
        <Box className="dark-page" sx={{ display: 'flex' }}>
            <Box component="main" sx={{ flexGrow: 1, pt: 25, pb: 25 }}>
                <Grid className="login">
                    {
                        !showForgotPassword && !showMFAScreen &&
                        <>
                            <img src={hseoLogo} alt="Logo" className="logo" />
                            <Typography variant="h4" style={{ textAlign: 'center' }} className="section-header">{APP_NAME}</Typography>
                            <TabContext value={currentTab}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList className="auth-tabs" onChange={handleTabChange} aria-label="Tabs">
                                        <Tab label="Log In" value="1" />
                                        <Tab label="Sign Up" value="2" />
                                    </TabList>
                                </Box>

                                <TabPanel value="1">
                                    <Login
                                        setShowMFAScreen={setShowMFAScreen}
                                        setShowForgotPassword={setShowForgotPassword}
                                        setSecretCode={setSecretCode}
                                        setMFAEnabled={setMFAEnabled}
                                    />
                                </TabPanel>

                                <TabPanel value="2">
                                    <Signup setCurrentTab={setCurrentTab} />
                                </TabPanel>
                            </TabContext>
                        </>
                    }

                    {showForgotPassword && <ForgotPassword setShowForgotPassword={setShowForgotPassword} />}

                    {showMFAScreen && <MFA secretCode={secretCode} mfaEnabled={mfaEnabled} />}
                </Grid>
            </Box>
        </Box>
    );
}