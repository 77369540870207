import * as React from 'react';
import {
    Box,
    CircularProgress,
    Grid,
    Typography,
} from '@mui/material';

export default function Progress({ text }) {

    function ProgressBox({ text }) {
        return (
            <Box className="progress-box" sx={{ 'display': "grid", 'placeItems': "center" }}>
                <CircularProgress />
                <Typography className="message" sx={{ mt: 3 }} variant="body1" component="div" color="text.secondary">{text}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Box component="main" sx={{ flexGrow: 1 }}>
                <Grid className="main-content">
                    <Box component="main" sx={{ flexGrow: 1, pt: 5 }}>
                        <ProgressBox text={text} />
                    </Box>
                </Grid>
            </Box>
        </Box>
    );
}