import React from 'react';
import {
    Box,
    Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export default function UploadProgressMessage({ uploadMessage }) {
    return uploadMessage ? (
        <>
            <Box className="upload-message-box">
                <Box sx={{ display: uploadMessage.level === "success" ? "flex" : "none" }}>
                    <CheckCircleIcon sx={{ pr: 2 }} className="success-icon" />
                    <Typography className="success-message">{uploadMessage.text}</Typography>
                </Box>

                <Box sx={{ display: uploadMessage.level === "error" ? "flex" : "none" }}>
                    <CancelIcon sx={{ pr: 2 }} className="error-icon" />
                    <Typography className="error-message">{uploadMessage.text}</Typography>
                </Box>
            </Box>
        </>
    ) : <></>;
}