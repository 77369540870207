import React, { useEffect, useState, useContext } from "react"
import { AccountContext } from "./Account";
import {
    Box,
    Button,
    Typography,
    OutlinedInput,
    Snackbar,
    Alert
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const AccountConfirmation = ({ email, setCurrentTab }) => {
    const [emailCode, setEmailCode] = useState("");
    const [notification, setNotification] = useState(null);
    const [requiredFieldsMissing, setRequiredFieldsMissing] = useState(true);

    const { confirmRegistration } = useContext(AccountContext);

    useEffect(() => {
        setRequiredFieldsMissing(!emailCode);
    }, [emailCode]);

    // const onSnackbarClose = (event, reason) => {
    //     if (reason === 'clickaway') return;
    // };

    // const verifyConfirmationCode = (event) => {
    //     event.preventDefault();

    //     setNotification(null);
    //     confirmRegistration(email, emailCode).then(data => {
    //         if (data === "SUCCESS") {
    //             setNotification({ "level": "success", "message": "Thanks for confirming your account." });

    //             setTimeout(() => {
    //                 setCurrentTab("1");
    //             }, 3000);
    //         }
    //     }).catch(err => {
    //         setNotification({ "level": "error", "message": err.message });
    //     });
    // }

    return (
        <Box>
            <Typography variant="h5">Thanks for creating an account with us.</Typography>
            <br />
            <Typography variant="body1">An administrator will review your permissions and confirm your account.</Typography>
        </Box>
        // <Box>
        //     <Typography variant="h4" style={{ textAlign: 'center' }} className="section-header">Verify your account</Typography>
        //     <Typography variant="body1">Please verify your account by entering the confirmation code sent to {email}.</Typography>
        //     <br />
        //     <Typography variant="body1">If you did not receive any code, please contact {CONTACT_US_EMAIL}.</Typography>

        //     <Box sx={{ 'display': "grid", 'placeItems': "center", mt: 3 }}>
        //         <Box className="input-container">
        //             <OutlinedInput
        //                 size="small"
        //                 name="emailCode"
        //                 id="emailCode"
        //                 onChange={e => setEmailCode(e.target.value)}
        //                 defaultValue={emailCode}
        //                 placeholder="Enter confirmation code"
        //             />
        //         </Box>

        //         <Box className="input-container">
        //             <Button onClick={verifyConfirmationCode} disabled={requiredFieldsMissing}>
        //                 Confirm Email
        //             </Button>
        //         </Box>
        //     </Box>

        //     <Snackbar open={notification !== null} autoHideDuration={3000} onClose={onSnackbarClose}>
        //         <Alert severity={notification?.level === "success" ? "success" : "error"}>{notification?.message}</Alert>
        //     </Snackbar>
        // </Box>
    )
}

export default AccountConfirmation;