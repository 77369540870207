import * as XLSX from 'xlsx';
import Helpers from './Helpers';

class XlsxUtil {
    static REQUIRED_TABS = ["FormData", "RawData"];
    static ALLOWED_EXTENSIONS = ['xls', 'xlsx', 'xlsm', 'xlsb', 'xltx'];
    static MAX_FILE_SIZE_MB = 2;
    static MAX_FILE_SIZE_BYTES = 1024 * 1024 * this.MAX_FILE_SIZE_MB; // 2MB

    static workbook = null;
    static worksheetNames = null;

    static isValidFileExtension(filename) {
        let tokens = filename.split(".");
        let ext = tokens[tokens.length - 1];

        return ext ? this.ALLOWED_EXTENSIONS.includes(ext?.toLowerCase()) : false;
    }

    static setWorkbook(file) {
        return new Promise((resolve, reject) => {
            try {
                // Initialize the FileReader object
                const reader = new FileReader();

                // Define an onLoad event handler to handle the loaded file
                reader.onload = (e) => {
                    const arrayBuffer = e.target.result;

                    // Parse the array buffer as a workbook
                    this.workbook = XLSX.read(arrayBuffer, { type: 'array' });

                    // Get the names of all worksheets in the spreadsheet
                    this.worksheetNames = this.workbook?.SheetNames;

                    resolve(true);
                };

                // Read the file as an array buffer
                reader.readAsArrayBuffer(file);
            } catch (error) {
                reject(error);
            }
        });
    }

    static getWorkbook() {
        return this.workbook;
    }

    static isRequiredColumnPresent(tabname, columnName) {
        const worksheet = this.workbook.Sheets[tabname];
        const columnRange = XLSX.utils.decode_range("A1:K1");

        return new Promise((resolve, reject) => {
            try {
                // Inspect all the column values from the first column (A1:Z1)
                for (let i = 0; i < columnRange.e.c; i++) {
                    const cellAddress = XLSX.utils.encode_cell({ c: columnRange.s.c + i, r: columnRange.s.r });
                    const nextRowAddress = XLSX.utils.encode_cell({ c: columnRange.s.c + i, r: columnRange.s.r + 1 });

                    const cellValue = worksheet[cellAddress]?.v || null;
                    const nextRowValue = worksheet[nextRowAddress]?.v || null;

                    if (cellValue !== undefined
                        && (cellValue === columnName && nextRowValue !== null)) {
                        resolve(nextRowValue);
                    }
                }

                reject(new Error(Helpers.ERR_CODES["COMPANY_CODE_ERROR"]));
            } catch (error) {
                reject(error);
            }
        });
    }

    static validateFileStructure(file) {
        return new Promise((resolve, reject) => {
            try {
                // Check if the required tab exist in the spreadsheet
                const tabsExist = this.REQUIRED_TABS.every((tabName) => this.worksheetNames.includes(tabName));

                // let companyCodeValue = this.isRequiredColumnPresent(this.workbook, "FormData", "company_code");

                // if (!companyCodeValue)
                //     reject(new Error(`Upload failed. No company_code found in FormData.`));
                if (!tabsExist)
                    reject(new Error(`Upload failed. The following tabs must be present in the document: "${this.REQUIRED_TABS}"`));

                resolve(true);
            } catch (error) {
                reject(error);
            }
        });
    }
}

export default XlsxUtil;