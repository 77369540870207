import React, { useState, useContext } from "react"
import { AccountContext } from "./Account";
import {
    Box,
    Button,
    Typography,
    OutlinedInput,
    InputAdornment,
    Alert,
    Snackbar
} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

const Login = ({ setShowMFAScreen, setShowForgotPassword, setSecretCode, setMFAEnabled }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [authError, setAuthError] = useState(null);

    const { authenticateUser } = useContext(AccountContext);

    const onSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
    };

    const onLogin = (event) => {
        event.preventDefault();

        setAuthError(null);

        authenticateUser(email, password).then(data => {
            // console.log(data);

            if (data.action === "generate_qr_code") {
                setSecretCode(data.secretCode);
                setShowMFAScreen(true);
                setMFAEnabled(false);
            }

            if (data.action === "request_login_code") {
                setMFAEnabled(true);
                setShowMFAScreen(true);
            }

        }).catch(err => {
            setAuthError(err.message);
        });
    }

    const handleForgotPassword = (event) => {
        event.preventDefault();

        setShowForgotPassword(true);
    }

    return (
        <Box>
            <Box sx={{ 'display': "grid", 'placeItems': "center", mt: 3 }}>
                <Box className="input-container">
                    <OutlinedInput
                        size="small"
                        name="email"
                        id="email"
                        onChange={e => setEmail(e.target.value)}
                        defaultValue={email}
                        placeholder="Email"
                        startAdornment={<InputAdornment className="datatype-icon" position="start"><PersonIcon /></InputAdornment>}
                    />
                </Box>
                <Box className="input-container">
                    <OutlinedInput
                        size="small"
                        name="password"
                        type="password"
                        id="password"
                        onChange={e => setPassword(e.target.value)}
                        defaultValue={password}
                        placeholder="Password"
                        startAdornment={<InputAdornment className="datatype-icon" position="start"><VpnKeyIcon /></InputAdornment>}
                    />
                </Box>

                <Box className="input-container" sx={{ p: 1 }}>
                    <Typography variant="body1" className="forgot-password-text" onClick={handleForgotPassword}>Forgot Password?</Typography>
                </Box>

                <Box className="input-container">
                    <Button onClick={onLogin} disabled={email === "" || password === ""}>
                        Log in
                    </Button>
                </Box>
            </Box>

            <Snackbar open={authError !== null} autoHideDuration={3000} onClose={onSnackbarClose}>
                <Alert severity="error">{authError}</Alert>
            </Snackbar>
        </Box>
    );
}

export default Login;