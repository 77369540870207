import axios from 'axios';
import Helpers from './Helpers';

// Class for making API requests on behalf of the application.
class ApiClient {
    static API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    /**
     * Submits an API request on behalf of the client. This function is called internally
     * @param {string} method - The HTTP method
     * @param {string} path - The HTTP path
     * @param {json} data - The body of the request
     * @returns {json} - The HTTP response in JSON format or an error string.
     */
    static async _handleRequest(method, path, data) {
        let url = this.API_ENDPOINT.concat(path);
        try {
            let options = {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: method
            };

            if (data)
                options.body = JSON.stringify(data);

            const response = await fetch(url, options);
            if (!response.ok)
                throw new Error(Helpers.ERR_CODES["STANDARD_ERROR"]);

            return await response.json();
        } catch (err) {
            console.error(err);
        }
    }

    static async handleFileUpload(path, file, setUploadProgress, setUploadMessage) {
        let url = this.API_ENDPOINT.concat(path);
        try {
            if (!file) throw new Error(Helpers.ERR_CODES["STANDARD_ERROR"]);

            let formData = new FormData();
            formData.append('file', file);
            formData.append('filename', file.name);

            let options = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
                onUploadProgress: function (progressEvent) {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                },
            };

            axios.post(url, formData, options).then((res) => {
                setUploadMessage({
                    "level": "success",
                    "text": res.data
                });
                setUploadProgress(100);
            }).catch((error) => {
                setUploadMessage({
                    "level": "error",
                    "text": Helpers.ERR_CODES["STANDARD_ERROR"]
                });
                console.error(error);
            });
        } catch (err) {
            setUploadMessage({
                "level": "error",
                "text": Helpers.ERR_CODES["STANDARD_ERROR"]
            });
            console.error(err);
        }
    }

    // static async handleFileUploadNoProgress(path, file) {
    //     let url = this.API_ENDPOINT.concat(path);
    //     try {
    //         if (!file) throw new Error("Could not parse file contents.");

    //         let formData = new FormData();
    //         formData.append('file', file);
    //         formData.append('filename', file.name);

    //         let options = {
    //             method: "POST",
    //             body: formData
    //         };

    //         const response = await fetch(url, options);
    //         if (!response.ok) throw new Error(`An error was encountered while processing your request.`);

    //         return await response.json();
    //     } catch (err) {
    //         console.error(err);
    //     }
    // }

    /**
     * Submits a GET request on behalf of the client
     * @param {string} path - The HTTP path
     * @returns {json} - The HTTP response in JSON format or an error string.
     */
    static async handleGET(path) {
        return await this._handleRequest("GET", path);
    }

    /**
     * Submits a POST request on behalf of the client
     * @param {string} path - The HTTP path
     * @param {json} data - The body of the request
     * @returns {json} - The HTTP response in JSON format or an error string.
     */
    static async handlePOST(path, data) {
        return await this._handleRequest("POST", path, data);
    }
}

export default ApiClient;