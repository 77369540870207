import React, { useEffect, useState } from 'react';
import {
    Box,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material'
import ApiClient from './utils/ApiClient';
import Helpers from './utils/Helpers';
import Progress from './Progress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';

function Submissions({ userCompanyDomain }) {
    const [submissions, setSubmissions] = useState([]);
    const [pageLoaded, setPageLoaded] = useState(false);

    // Trigger this on page load and file submission success.
    useEffect(() => {
        loadTableData();
    }, []);

    const getStatusColor = (status) => {
        status = status.toLowerCase();
        switch (status) {
            case "succeeded":
            case "failed":
                return status;
            case "in progress":
                return "in-progress";
            default:
                return "";
        }
    }

    const loadTableData = async () => {
        setPageLoaded(false);

        let submissionsFromAPI = await ApiClient.handleGET(`submissions?domain=${userCompanyDomain}`);
        // console.log("submissionsFromAPI", submissionsFromAPI);

        setSubmissions(submissionsFromAPI || []);
        setPageLoaded(true);
    }

    return (
        <>
            <Typography variant="h4" className="section-header">
                My Submissions
                <RefreshIcon onClick={e => loadTableData()} className="submissions-tbl-icon" />
            </Typography>
            <Divider />

            {!pageLoaded &&
                <Progress text="Loading table..." />
            }

            {pageLoaded && submissions.length > 0 &&
                <TableContainer className="submissions" sx={{ mt: 3 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Submitted On</TableCell>
                                <TableCell>File</TableCell>
                                <TableCell>DBEDT</TableCell>
                                <TableCell>Report Period</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Message</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {submissions.map((sb) => (
                                <TableRow key={sb.id} >
                                    <TableCell>{Helpers.getReadableDate(sb.uploaded_on)}</TableCell>
                                    <TableCell style={{ width: '20%' }}>{sb.filename}</TableCell>
                                    <TableCell>{sb.dbedt}</TableCell>
                                    <TableCell>{sb.report_period}</TableCell>
                                    <TableCell className={`state ${getStatusColor(sb.status)}`}>
                                        <CheckCircleIcon className={getStatusColor(sb.status) === "succeeded" ? "success-icon" : "hideme"} />
                                        <CancelIcon className={getStatusColor(sb.status) === "failed" ? "error-icon" : "hideme"} />
                                        <InfoIcon className={getStatusColor(sb.status) === "in-progress" ? "in-progress-icon" : "hideme"} />

                                        {sb.status}
                                    </TableCell>
                                    <TableCell style={{ width: '40%' }}>{sb.message}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer >
            }

            {pageLoaded && submissions.length === 0 &&
                <Box sx={{ mt: 3 }}>
                    <Typography textAlign={'center'}>No files submitted yet.</Typography>
                </Box>
            }
        </>
    );
}

export default Submissions;