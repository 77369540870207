import React, { useEffect, useState, useContext } from "react"
import { CognitoUserAttribute } from "amazon-cognito-identity-js"
import { AccountContext } from "./Account";
import {
    Box,
    Button,
    Typography,
    OutlinedInput,
    InputAdornment,
    Alert,
    Select,
    MenuItem,
    Snackbar
} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PhoneIcon from '@mui/icons-material/Phone';
import ApiClient from '../utils/ApiClient';
import AccountConfirmation from "./AccountConfirmation";

const Signup = ({ setCurrentTab }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordSecondTime, setPasswordSecondTime] = useState("");
    const [phone, setPhone] = useState("");
    const [userCompanyDomain, setUserCompanyDomain] = useState("");
    const [companies, setCompanies] = useState([]);
    const [notification, setNotification] = useState(null);
    const [requiredFieldsMissing, setRequiredFieldsMissing] = useState(true);
    const [showAccountConfirmation, setAccountConfirmation] = useState(false);

    const passwordRequirements = [
        { "name": "Minimum 8 character(s)" },
        { "name": "Must contain at least 1 number" },
        { "name": "Must contain at least 1 uppercase letter" },
        { "name": "Must contain at least 1 lowercase letter" },
        { "name": "Must contain at least 1 special character" }
    ]

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 300,
            },
        },
    };

    const { signUp } = useContext(AccountContext);

    useEffect(() => {
        (async () => {
            let companiesFromAPI = await ApiClient.handleGET(`companies/`);
            let groupedCompaniesByDomain = companiesFromAPI.reduce((acc, company) => {
                let existingElem = acc.find(c => c.domain === company.domain);
                if (existingElem)
                    existingElem.names = `${existingElem.names} / ${company.full_name}`;
                else
                    acc.push({
                        "domain": company.domain,
                        "names": company.full_name
                    });
                return acc;
            }, []);

            // console.log("companiesFromAPI", companiesFromAPI, groupedCompaniesByDomain);
            setCompanies(groupedCompaniesByDomain || []);
        })();
    }, []);

    useEffect(() => {
        setRequiredFieldsMissing(!email || !password || !passwordSecondTime || !userCompanyDomain || !phone);
    }, [email, password, passwordSecondTime, userCompanyDomain, phone]);

    const onSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
    };

    const onSignUp = (event) => {
        event.preventDefault();

        let domain = email.split('@')[1] || "";
        // if (domain !== "icf.com" && domain !== userCompanyDomain) {
        //     // if (domain !== userCompanyDomain) {
        //     setNotification({ "level": "error", "message": "Your email domain does not match the selected company's" });
        //     return;
        // }

        if (password !== passwordSecondTime) {
            setNotification({ "level": "error", "message": "Your new password does not match" });
            return;
        }

        let attributeList = [];
        attributeList.push(new CognitoUserAttribute(
            { Name: 'custom:company_domain', Value: String(userCompanyDomain) }
        ));
        attributeList.push(new CognitoUserAttribute(
            { Name: 'phone_number', Value: `+1${phone}` }
        ));

        signUp(email, password, attributeList, null).then(data => {
            setAccountConfirmation(true);
        }).catch(err => {
            setNotification({ "level": "error", "message": err.message });
        });
    }

    return !showAccountConfirmation ? (
        <Box>
            <Typography variant="h4" style={{ textAlign: 'center' }}>Register an Account</Typography>
            <Typography variant="body2" style={{ textAlign: 'center', paddingTop: 5 }}>All fields are required</Typography>

            <Box sx={{ 'display': "grid", 'placeItems': "center", mt: 3 }}>
                <Box className="input-container">
                    <OutlinedInput
                        size="small"
                        name="email"
                        id="email"
                        onChange={e => setEmail(e.target.value)}
                        defaultValue={email}
                        placeholder="Email"
                        startAdornment={<InputAdornment position="start"><PersonIcon /></InputAdornment>}
                    />
                </Box>
                <Box className="input-container">
                    <OutlinedInput
                        size="small"
                        name="password"
                        type="password"
                        id="password"
                        onChange={e => setPassword(e.target.value)}
                        defaultValue={password}
                        placeholder="Password"
                        startAdornment={<InputAdornment position="start"><VpnKeyIcon /></InputAdornment>}
                    />
                </Box>
                <Box className="input-container">
                    <OutlinedInput
                        size="small"
                        name="passwordSecondTime"
                        type="password"
                        id="passwordSecondTime"
                        onChange={e => setPasswordSecondTime(e.target.value)}
                        defaultValue={passwordSecondTime}
                        placeholder="Confirm Password"
                        startAdornment={<InputAdornment position="start"><VpnKeyIcon /></InputAdornment>}
                    />
                </Box>

                <Box sx={{ margin: '0px 45px' }}>
                    <ul>
                        {passwordRequirements.map((option, index) => (
                            <li key={index} className="input-container">{option.name}</li>
                        ))}
                    </ul>
                </Box>

                <Box className="input-container">
                    <OutlinedInput
                        size="small"
                        name="phone_number"
                        id="phone_number"
                        onChange={e => setPhone(e.target.value)}
                        defaultValue={phone}
                        placeholder="Phone Number"
                        startAdornment={<><InputAdornment position="start"><PhoneIcon /></InputAdornment>+1</>}
                    />
                </Box>

                <Box className="input-container">
                    <Select
                        labelId="companies-select-label"
                        size="small"
                        id="companies"
                        className="select-ul"
                        value={userCompanyDomain}
                        displayEmpty
                        MenuProps={MenuProps}
                        onChange={e => setUserCompanyDomain(e.target.value)}
                        startAdornment={<InputAdornment position="start"><ApartmentIcon /></InputAdornment>}
                    >
                        <MenuItem value="">
                            <em>Select your company</em>
                        </MenuItem>
                        {companies.map((company, index) => (
                            <MenuItem key={index} value={company.domain} sx={{ whiteSpace: 'break-spaces' }}>
                                {company.names}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>

                <Box className="input-container">
                    <Button onClick={onSignUp} disabled={requiredFieldsMissing}>
                        Sign up
                    </Button>
                </Box>
            </Box>

            <Snackbar open={notification !== null} autoHideDuration={3000} onClose={onSnackbarClose}>
                <Alert severity={notification?.level === "success" ? "success" : "error"}>{notification?.message}</Alert>
            </Snackbar>
        </Box>
    ) : <AccountConfirmation email={email} setCurrentTab={setCurrentTab} />
}

export default Signup;