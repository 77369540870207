import React, { createContext } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';
import Pool from './UserPool';

const AccountContext = createContext();

const Account = (props) => {

    let currentUser = null;

    const getSession = async () => {
        return await new Promise((resolve, reject) => {
            const currentUser = Pool.getCurrentUser();
            // console.log('currentUser', currentUser);

            if (currentUser) {
                currentUser.getSession(async (err, session) => {

                    var refresh_token = session.getRefreshToken();
                    const idToken = session.getIdToken().jwtToken;
                    const accessToken = session.getAccessToken().jwtToken;

                    //console.log('refresh_token', refresh_token, 'idToken', idToken, 'accessToken', accessToken);

                    if (err) {
                        reject(err)
                    } else {
                        const attributes = await new Promise((resolve, reject) => {
                            currentUser.getUserAttributes((err, attributes) => {
                                if (err) {
                                    reject(err)
                                } else {
                                    // console.log('attributes:', attributes)
                                    const results = {}

                                    for (let attribute of attributes) {
                                        const { Name, Value } = attribute
                                        results[Name] = Value
                                    }

                                    resolve(results);
                                }
                            })
                        });

                        const accessToken = session.accessToken.jwtToken
                        const token = session.getIdToken().getJwtToken()

                        resolve({
                            currentUser,
                            accessToken,
                            headers: {
                                Authorization: token,
                            },
                            ...session,
                            attributes,
                        })
                    }
                });
            }
            else {
                reject();
            }
        });
    };

    const signUp = async (email, password, attributeList) => {
        return await new Promise((resolve, reject) => {
            Pool.signUp(email, password, attributeList, null, (err, data) => {
                if (err) reject(err);
                resolve(data);
            });
        });
    };

    const confirmRegistration = async (email, confirmationCode) => {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({
                Username: email,
                Pool: Pool
            });

            user.confirmRegistration(confirmationCode, true, function (err, result) {
                if (err) {
                    reject(err);
                }
                resolve(result);
            });
        });
    };

    const forgotPassword = async (email) => {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({
                Username: email,
                Pool: Pool
            });

            user.forgotPassword({
                onSuccess: function (data) {
                    // successfully initiated reset password request
                    resolve(data);
                },
                onFailure: function (err) {
                    alert(err.message || JSON.stringify(err));
                    reject(err);
                },
                //Optional automatic callback
                inputVerificationCode: function (data) {
                    resolve(data);
                },
            });
        });
    }

    const confirmPassword = async (email, emailCode, newPassword) => {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({
                Username: email,
                Pool: Pool
            });

            user.confirmPassword(emailCode, newPassword, {
                onFailure(err) {
                    reject(err);
                },
                onSuccess(data) {
                    resolve(data);
                },
            });
        });
    }

    const verifySoftwareToken = async (totpCode) => {
        return await new Promise((resolve, reject) => {

            currentUser.verifySoftwareToken(totpCode, 'HSEO EIIRP', {
                onSuccess: (data) => {
                    // console.log('success', data);
                    resolve(true);
                },
                onFailure: (err) => {
                    // console.error('error', err);
                    reject(err);
                },
            });
        });
    }

    const sendMFACode = async (totpCode) => {
        return await new Promise((resolve, reject) => {

            currentUser.sendMFACode(totpCode, {
                onSuccess: (data) => {
                    // console.log('success', data);
                    resolve(true);
                },
                onFailure: (err) => {
                    // console.error('error', err);
                    reject(err);
                },
            }, 'SOFTWARE_TOKEN_MFA');
        });
    }

    const authenticateUser = async (email, password) => {
        return await new Promise((resolve, reject) => {
            currentUser = new CognitoUser({
                Pool: Pool,
                Username: email
            });

            const authDetails = new AuthenticationDetails({
                Username: email,
                Password: password
            });

            currentUser.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    // console.log('success', data);
                    resolve(data);
                },
                onFailure: (err) => {
                    // console.error('error', err);
                    reject(err);
                },
                mfaSetup: function (challengeName, challengeParameters) {
                    // Generate a secret code to enforce / enable MFA
                    currentUser.associateSoftwareToken(this);
                },
                associateSecretCode: function (secretCode) {

                    // This code will be passed on to generate the QR Code
                    resolve({ "action": "generate_qr_code", "secretCode": secretCode });
                },

                selectMFAType: function (challengeName, challengeParameters) {
                    // var mfaType = prompt('Please select the MFA method.', ''); // valid values for mfaType is "SMS_MFA", "SOFTWARE_TOKEN_MFA"
                    // user.sendMFASelectionAnswer(mfaType, this);
                },

                totpRequired: function (secretCode) {
                    resolve({ "action": "request_login_code" });
                },

                mfaRequired: function (codeDeliveryDetails) { },

                newPasswordRequired: (data) => {
                    resolve(data);
                }
            });
        });
    };

    const logout = async () => {
        const user = Pool.getCurrentUser();
        if (user) user.signOut();
    };

    return (
        <AccountContext.Provider value={{ authenticateUser, confirmRegistration, getSession, signUp, forgotPassword, confirmPassword, verifySoftwareToken, logout, sendMFACode }}>
            {props.children}
        </AccountContext.Provider>
    )
}

export { Account, AccountContext };