import React, { useEffect, useState, useContext } from "react"
import { useNavigate } from "react-router-dom";
import { AccountContext } from "./Account";
import {
    Box,
    Button,
    Typography,
    Snackbar,
    Alert,
    OutlinedInput,
    Stepper,
    Stack,
    Step,
    StepLabel
} from '@mui/material'
import Progress from "../Progress";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import QRCode from 'qrcode'

const MFA = ({ secretCode, mfaEnabled }) => {
    const navigate = useNavigate();

    const [notification, setNotification] = useState(null);
    const [activeStep, setActiveStep] = React.useState(0);
    const [requiredFieldsMissing, setRequiredFieldsMissing] = useState(true);
    const [totpCode, setTotpCode] = useState('');
    const [qrCodeLoaded, setQrCodeLoaded] = useState(false);
    const [image, setImage] = useState('')

    const { verifySoftwareToken, sendMFACode } = useContext(AccountContext);

    const steps = [
        'Instructions',
        'Scan QR Code',
        'Enter MFA Token'
    ];

    useEffect(() => {
        if (activeStep === 1)
            getQRCode();
    }, [activeStep]);

    useEffect(() => {
        setRequiredFieldsMissing(!totpCode);
    }, [totpCode]);

    const getQRCode = () => {
        // console.log('Generate QR code based on', secretCode);

        const name = 'HSEO EIIRP';
        const uri = `otpauth://totp/${decodeURI(name)}?secret=${secretCode}`;

        QRCode.toDataURL(uri).then(url => {
            setImage(url);
            setQrCodeLoaded(true);
        }).catch(err => {
            console.error(err)
        });
    }

    const onSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
    };

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            setNotification(null);

            verifySoftwareToken(totpCode).then(data => {
                setNotification({ "level": "success", "message": "MFA setup complete!" });
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }).catch(err => {
                setNotification({ "level": "error", "message": err.message });
            });

        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleDashboardRedirect = () => {
        navigate(`/dashboard`);
    };

    const handleMFA = () => {
        sendMFACode(totpCode).then(data => {
            navigate('/dashboard');
        }).catch(err => {
            setNotification({ "level": "error", "message": err.message });
        });
    }

    const handleBack = () => {
        if (activeStep > 0)
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        else {
            navigate('/');
            window.location.reload();
        }
    }

    return (
        <>
            {
                mfaEnabled &&
                <>
                    <Typography variant="h4" style={{ textAlign: 'center' }} className="section-header">Multi-Factor Authentication</Typography>
                    <Typography variant="h6" style={{ textAlign: 'center' }}>Please enter the 6-digit MFA code from your auth app below.</Typography>

                    <Box sx={{ 'display': "grid", 'placeItems': "center", mt: 3 }}>
                        <Box>
                            <Box className="input-container">
                                <OutlinedInput
                                    size="small"
                                    name="totpCode"
                                    id="totpCode"
                                    onChange={e => setTotpCode(e.target.value)}
                                    defaultValue={totpCode}
                                    placeholder="Enter your MFA code"
                                />
                            </Box>
                        </Box>

                        <Box className="input-container">
                            <Button onClick={handleMFA} disabled={requiredFieldsMissing}>
                                Continue
                            </Button>
                        </Box>
                    </Box>
                </>

            }

            {
                !mfaEnabled &&
                <Box>
                    <Stack justifyContent="flex-start"
                        sx={{ mb: 3, cursor: 'pointer' }}
                        direction="row" spacing={2}
                        disabled={activeStep === 0}
                        onClick={handleBack}
                    >
                        <ArrowBackIcon />
                        <Typography>Back</Typography>
                    </Stack>

                    <Typography variant="h4" style={{ textAlign: 'center' }} className="section-header">Complete Setup</Typography>
                    <Typography variant="h6" style={{ textAlign: 'center' }}>Let's finish getting you set up with MFA.</Typography>

                    <Box className="mfa-setup" sx={{ 'display': "grid", 'placeItems': "center", mt: 3 }}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>

                        {activeStep === steps.length ? (
                            <Box sx={{ 'display': "grid", 'placeItems': "center", m: 3 }}>

                                <Typography variant="h5" sx={{ m: 3 }}>
                                    <CheckCircleIcon className="success-icon" /> You're all set up!
                                </Typography>

                                <Box className="input-container">
                                    <Button onClick={handleDashboardRedirect}>
                                        Go to Dashboard
                                    </Button>
                                </Box>
                            </Box>
                        ) : (
                            <>
                                {activeStep === 0 &&
                                    <Box sx={{ m: 5 }}>
                                        <Typography variant="p">
                                            The administrator requires <b>multi-factor authentication (MFA)</b> for your account.
                                            <br />
                                            <br />
                                            Before we proceed, please make sure to have an authenticator app installed on your phone, such as Google Authenticator or Duo Mobile.
                                        </Typography>
                                    </Box>
                                }

                                {activeStep === 1 &&
                                    <Box sx={{ 'display': "grid", 'placeItems': "center", m: 3 }}>
                                        <ol className="mfa_steps">
                                            <li>Scan QR code with your auth app.</li>
                                            <li>Enter the generated MFA code into the MFA box in the next step.</li>
                                            <li>On future logins, enter the code without spaces from your auth app.</li>
                                        </ol>

                                        {qrCodeLoaded ?
                                            <img src={image} className="qrcode-container" /> :
                                            <Progress text="" />
                                        }
                                    </Box>
                                }

                                {activeStep === 2 &&
                                    <Box sx={{ 'display': "grid", 'placeItems': "center", m: 3 }}>
                                        <Typography variant="p">Please enter the 6-digit MFA code for profile <b>HSEO EIIRP</b> from your auth app.</Typography>

                                        <Box className="input-container">
                                            <OutlinedInput
                                                size="small"
                                                name="totpCode"
                                                id="totpCode"
                                                onChange={e => setTotpCode(e.target.value)}
                                                defaultValue={totpCode}
                                                placeholder="MFA Code"
                                            />
                                        </Box>
                                    </Box>
                                }

                                <Box className="input-container">
                                    <Button onClick={handleNext} disabled={activeStep === steps.length - 1 && requiredFieldsMissing}>
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Box>

                    <Snackbar open={notification !== null} autoHideDuration={3000} onClose={onSnackbarClose}>
                        <Alert severity={notification?.level === "success" ? "success" : "error"}>{notification?.message}</Alert>
                    </Snackbar>
                </Box >
            }

            <Snackbar open={notification !== null} autoHideDuration={3000} onClose={onSnackbarClose}>
                <Alert severity={notification?.level === "success" ? "success" : "error"}>{notification?.message}</Alert>
            </Snackbar>
        </>
    )
}

export default MFA;