import React, { useEffect, useState, useContext } from "react"
import { AccountContext } from "./Account";
import {
    Box,
    Button,
    Typography,
    OutlinedInput,
    Snackbar,
    Alert,
    Stack
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ForgotPassword = ({ setShowForgotPassword }) => {
    const CONTACT_US_EMAIL = process.env.REACT_APP_CONTACT_US_EMAIL || "";

    const [email, setEmail] = useState("");
    const [emailCode, setEmailCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordSecondTime, setNewPasswordSecondTime] = useState("");
    const [notification, setNotification] = useState(null);
    const [showAdditionalFields, setShowAdditionalFields] = useState(false);
    const [requiredFieldsMissing, setRequiredFieldsMissing] = useState(true);

    const { confirmPassword, forgotPassword } = useContext(AccountContext);

    useEffect(() => {
        setRequiredFieldsMissing(!email);
    }, [email]);

    const onSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
    };

    const sendAccountRecoveryCode = (event) => {
        event.preventDefault();

        setNotification(null);
        forgotPassword(email).then(data => {
            setShowAdditionalFields(true);
        }).catch(err => {
            setNotification({ "level": "error", "message": err.message });
        });
    }

    const resetPassword = (event) => {
        event.preventDefault();

        setNotification(null);
        if (newPassword !== newPasswordSecondTime) {
            setNotification({ "level": "error", "message": "Your new password does not match" });
            return;
        }

        confirmPassword(email, emailCode, newPassword).then(data => {
            setNotification({ "level": "success", "message": "Your password was changed successfully" });

            setTimeout(() => {
                setShowForgotPassword(false);
            }, 3000);
        }).catch(err => {
            setNotification({ "level": "error", "message": err.message });
        });
    }

    return (
        <Box>
            <Stack justifyContent="flex-start"
                sx={{ mb: 3, cursor: 'pointer' }}
                direction="row" spacing={2}
                onClick={e => setShowForgotPassword(false)}>
                <ArrowBackIcon />
                <Typography>Back</Typography>
            </Stack>

            <Typography variant="h4" style={{ textAlign: 'center' }} className="section-header">Account Recovery</Typography>
            <Typography variant="body1">Please provide your email address and we'll send you a code to update your password.</Typography>

            <Box sx={{ 'display': "grid", 'placeItems': "center", mt: 3 }}>
                {!showAdditionalFields &&
                    <>
                        <Box className="input-container">
                            <OutlinedInput
                                size="small"
                                name="email"
                                id="email"
                                onChange={e => setEmail(e.target.value)}
                                defaultValue={email}
                                placeholder="Enter your email address"
                            />
                        </Box>

                        <Box className="input-container">
                            <Button onClick={sendAccountRecoveryCode} disabled={requiredFieldsMissing}>
                                Continue
                            </Button>
                        </Box>
                    </>
                }

                {showAdditionalFields &&
                    <>
                        <Box className="input-container">
                            <OutlinedInput
                                size="small"
                                name="email-disabled"
                                id="email-disabled"
                                disabled
                                defaultValue={email}
                            />
                        </Box>

                        <Box className="input-container">
                            <OutlinedInput
                                size="small"
                                name="emailCode"
                                id="emailCode"
                                onChange={e => setEmailCode(e.target.value)}
                                defaultValue={emailCode}
                                placeholder="Enter email verification code"
                            />
                        </Box>

                        <Box className="input-container">
                            <OutlinedInput
                                size="small"
                                name="newPassword"
                                id="newPassword"
                                type="password"
                                onChange={e => setNewPassword(e.target.value)}
                                defaultValue={newPassword}
                                placeholder="Enter your new password"
                            />
                        </Box>

                        <Box className="input-container">
                            <OutlinedInput
                                size="small"
                                name="newPasswordSecondTime"
                                type="password"
                                id="newPasswornewPasswordSecondTime"
                                onChange={e => setNewPasswordSecondTime(e.target.value)}
                                defaultValue={newPasswordSecondTime}
                                placeholder="Confirm new password"
                            />
                        </Box>

                        <Box className="input-container">
                            <Typography variant="body1">Please contact <b>{CONTACT_US_EMAIL}</b> if you did not receive any code.</Typography>
                        </Box>

                        <Box className="input-container">
                            <Button onClick={resetPassword} disabled={requiredFieldsMissing}>
                                Update Password
                            </Button>
                        </Box>
                    </>
                }
            </Box>

            <Snackbar open={notification !== null} autoHideDuration={3000} onClose={onSnackbarClose}>
                <Alert severity={notification?.level === "success" ? "success" : "error"}>{notification?.message}</Alert>
            </Snackbar>
        </Box>
    )
}

export default ForgotPassword;