import { default as moment } from 'moment';

/**
 ** Provides useful functions that can be called from the components. 
 */
class Helpers {

    static ERR_CODES = {
        "COMPANY_CODE_ERROR": "Missing required field on Part 1. Please fill in and submit.​",
        "INVALID_FILE_EXTENSION": "You submitted the wrong file type. Please submit the filled out template at https://dbedt.hawaii.gov/economic/eiirp/",
        "INVALID_FILE_SIZE": "File size should not exceed",
        "STANDARD_ERROR": "An error occurred with the file upload. Please contact dbedt.hseo.eiirp@hawaii.gov.​",
    }

    static isValidJSON(obj) {
        try {
            return JSON.parse(obj);
        } catch (e) {
            return false;
        }
    }

    // https://devhints.io/moment
    static getReadableDate(timestamp) {
        const date = moment(timestamp).format('YYYY-MM-DD HH:mm:ss');
        return date;
    }

    static capitalize(string) {
        return (string !== "") ? string.charAt(0).toUpperCase() + string.slice(1) : "";
    }
}

export default Helpers;